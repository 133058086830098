import React, { Fragment, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useNavigate } from 'react-router-dom';
import ddLogo from '../../../assets/img/landingpageImage/logo.png';
import { t } from 'i18next';
import iconSign from '../../../assets/img/landingpageImage/icon-sign.png';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import privacyImg from '../../../assets/img/landingpageImage/privacy.png';
import logofooterImg from '../../../assets/img/landingpageImage/logo-footer.png';

const Privacy = () => {
    const navigate = useNavigate();
    const [stickyHeader, setStickyHeader] = useState("");
    const navbarClasses = `navbar-main pb-4 pb-lg-0 ${stickyHeader}`;
    const [isVisible, setIsVisible] = useState(false);
    const sectionStyle = {
        backgroundImage: `url(${privacyImg})`
    };
    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyHeaderClass = scrollTop >= 300 ? "stiky" : "";
        setStickyHeader(stickyHeaderClass);
    };
    // Top: 0 takes us all the way back to the top of the page
    // Behavior: smooth keeps it smooth!
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        // document.getElementsByClassName('header')[0].classList.add("d-none");
        window.addEventListener("scroll", isSticky);

        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", isSticky);
            window.removeEventListener("scroll", toggleVisibility);
        };

    }, [])


    return (
        <Fragment>
            <PageWrapper className='p-0 landingpageWrap'>
                <Page className='p-0'>
                    {/* HEADER  */}
                    <div className="header header-1 m-0 p-0">

                        {/* TOPBAR  */}
                        <div className="topbar">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-sm-8 col-md-6">
                                        <div className="info">
                                            {/* <div className="info-item">
                                                <i className="fa fa-phone"></i> +1 (786) 873-1002
                                            </div> */}
                                            <div className="info-item">
                                                <i className="fa fa-envelope-o"></i> <a href="mailto:support@kids.com" title="">help@diligentdollars.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-6">
                                        <div className="sosmed-icon pull-right d-inline-flex">
                                            <a href="#" className="fb"><i className="fa fa-facebook"></i></a>
                                            <a href="#" className="tw"><i className="fa fa-twitter"></i></a>
                                            <a href="#" className="ig"><i className="fa fa-instagram"></i></a>
                                            <a href="#" className="in"><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* NAVBAR SECTION */}
                        <div className={navbarClasses}>
                            <div className="container">
                                <nav id="navbar-example" className="navbar navbar-expand-lg d-flex justify-content-between">
                                    <a className="navbar-brand" href="index.php">
                                        <img src={ddLogo} alt="" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">

                                        <div className="d-flex">
                                            <a href="#" className="button text-white d-inline-flex align-items-center bg-tertiary rounded-pill me-2">
                                                <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512">
                                                        <path fill="#ffffff" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                                    </svg>
                                                </span>
                                                <a href="#" rel="noreferrer" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                    onClick={() => { localStorage.setItem('tab', '0'); window.open('/auth/login?tab=0', '_blank') }}
                                                >
                                                    {t('login')}
                                                </a>
                                            </a>
                                        </div>

                                        <div className="d-flex">
                                            <a href="#" className="button text-white d-inline-flex align-items-center bg-primary rounded-pill">
                                                <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                    <img className="me-2 img-fluid" src={iconSign} alt="Imagen" />
                                                </span>
                                                <a href="javascript:void(0);" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                    onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                    {t('signUp')}
                                                </a>
                                            </a>
                                        </div>
                                    </div>
                                </nav>

                            </div>
                        </div>

                    </div>
                    {/* BACK TO TOP SECTION  */}
                    {isVisible && (<a className="cd-top cd-is-visible cd-fade-out cursor-pointer" onClick={scrollToTop}>Top</a>)}

                    {/* Privacy Policy  */}
                    <div className="privacy">
                        <div className='container'>
                            <div className='privacy-bg' data-background={privacyImg} style={sectionStyle}>
                                <div className='d-flex flex-column h-100 align-items-start justify-content-start pr-5 pt-4'>
                                    <h1 className='fw-bold font-primary color-primary mb-2'>Diligent Dollars Privacy Policy</h1>               
                                    <p>This Privacy Policy was last updated on October 2, 2024.</p>                
                                </div>
                            </div>
                            <div className='mt-4'>
                                <p>Diligent Dollars takes the privacy and protection of personal information for you and your family members very seriously. By visiting the Site and/or using the Diligent Dollars Services, you acknowledge that you accept the practices and policies outlined in this privacy policy <strong>("Privacy Policy")</strong>. Unless otherwise defined herein, capitalized terms shall have the meanings assigned to such terms set forth in the Diligent Dollars Terms of Use which incorporates this Privacy Policy by reference.</p>
                            </div>
                        </div>
                    </div>
                    <div className='py-4'>
                        <div className='container'>
                            <h5 className='fs-4 fw-bold'>What This Privacy Policy Covers</h5>
                            <p>This Privacy Policy describes Diligent Dollars’s use of Personal Information and Anonymous Information that we collect when you use our Diligent Dollars Services. This Privacy Policy does not apply to the use of your Personal Information that is collected by third parties that Diligent Dollars does not own or control.</p>
                            <p className='mt-4'><strong>"Personal Information"</strong> is information that is personally identifiable like names, addresses, e-mail addresses, or phone numbers, as well as other non-public information that is associated with the foregoing. Personal Information may include information that is personally identifiable information as defined by applicable law.</p>                    
                            <p className='mt-4'>
                                <strong>"Anonymous Information"</strong> means information that is not Personal Information; Anonymous Information does not, itself, identify individual persons.
                            </p>
                            <p className='mt-4'>We collect and use Personal Information and Anonymous Information as described in this Privacy Policy from the visitors of our Site, and from Users.</p>
                            <p className='mt-4'>
                                <strong>By using the Diligent Dollars Services or submitting Personal Information through our Diligent Dollars Services, you expressly consent to the processing of your Personal Information according to this Privacy Policy.</strong>
                            </p>
                            <p className='mt-4'>
                                <strong>Your Personal Information may be processed by us in the country where it was collected as well as other countries (including the United States) where laws regarding processing of Personal Information may be less stringent than the laws in your country.</strong>
                            </p>

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Personal Information We Collect</h5>
                            <p className='mt-0'>
                                We collect the following Personal Information that you submit to us voluntarily:
                            </p>
                            <ul>
                                <li>Our Account registration process requires you to provide Diligent Dollars with a valid e-mail address as well as a Sign-In name and password of your choice. If you want to use some of the mobile features of the Diligent Dollars Services, we will collect your mobile phone number.</li>
                                <li>In order to receive payments you make to use the Diligent Dollars Services, we collect the information necessary to charge your credit card, including your full name, zip code, country of residence and credit card number.</li>                
                                <li>When you contact us by sending us an e-mail, text message, or when you use any of our contact forms, we will collect any information that is contained in your e-mail, text message, or that you enter into such forms.</li>                
                            </ul>

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Personal Information Collected Via Technology</h5>
                            <p>As you use the Diligent Dollars Services, certain information may also be collected passively, including your Internet protocol (IP) address and the browser that you use to visit the Site. The Services create a log file of the IP addresses of the visitors and Users of the Services. Diligent Dollars also stores a small text file called a "Cookie" on your computer to store your login information and your personal preferences for the Diligent Dollars Services. Diligent Dollars may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them). While you will still be able to use the Services if you disable Cookies in your browser, certain parts of the Services may not function as effectively. You must have Cookies enabled in your web browser to use the Diligent Dollars Services. When you send us a text message, we collect the mobile phone number from which you sent us such text message.</p>
                            <p className='mt-4'>Since the foregoing information may be collected when you are signed into the Diligent Dollars Services as a User, this information may be associated by us with Personal Information that we have collected from you.</p>                    
                        
                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Personal Information Collected From Other Sources</h5>
                            <p>We may receive Personal Information about you from other sources like telephone or fax, or from third parties that provide services for us in connection with the Diligent Dollars Services. We may add this information to the information we have already collected from you via our Diligent Dollars Services.</p>
                            

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Use Of Information We Collect</h5>
                            <p>In general, Personal Information you submit to us is used by us either to register you for our Diligent Dollars Services, to provide the Diligent Dollars Services to you, to respond to requests that you make, to improve our Diligent Dollars Services and to better tailor the features, performance and support of the Diligent Dollars Services, and/or to offer you additional information, opportunities, and functionality.</p>
                            <p className='mt-4'>
                                We may also use your Personal Information to provide you with notifications related to your use of the Services (including notifications we may send to your mobile phone) such as summaries of transactions or the current balance of an Account, with electronic newsletters, or with promotional e-mails, should you request to receive such communications from us.
                            </p>
                            <p className='mt-4'>
                                Please note that the information you provide to the Diligent Dollars Services may be accessible by other Users that are using the same Family Account, depending on the setting of this Family Account and your status within such Family Account. Therefore, you should assume that information that you enter into the Diligent Dollars Services may be shared with other Users of your Family Account.
                            </p>
                            <p className='mt-4'>
                                We may create Anonymous Information records from Personal Information by excluding information (such as your name) that makes the information personally identifiable to you. We may use this Anonymous Information for internal purposes, such as analyzing usage patterns so that we may enhance our Diligent Dollars Services, and we also reserve the right to use and disclose any Anonymous Information at our discretion.
                            </p>

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Feedback</h5>
                            <p>If you provide feedback to us, we may use and disclose such feedback for any purpose, provided we do not associate such feedback with your Personal Information. We will collect any information contained in such feedback and will treat the Personal Information in it in accordance with this Privacy Policy.</p>
                        
                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Disclosure of Personal Information</h5>
                            <p>Except as otherwise stated in this Privacy Policy, we do not use or share your Personal Information with third parties, unless you ask or authorize us to do so.</p>
                            <p className='mt-4'>
                                We may provide your Personal Information to third party service providers and suppliers who work on behalf of or with us to provide some of the services and features of the Diligent Dollars Services and to help us communicate with Users. Examples of such services include sending e-mail or messages to your mobile phone, processing credit card payments, and providing customer service. However, these service providers and suppliers do not have any independent right to use this information except as to help us providing the Services, to provide you with notification of their services, (e.g., providers through whom you were referred to Diligent Dollars) or as otherwise allowed by you or required by law. These service providers may have the same rights to disclose such information as Diligent Dollars has under this section "Disclosure of Personal Information".
                            </p>                 
                            <p className='mt-4'>
                                Although we currently do not have a parent company, any subsidiaries, joint ventures, or other companies under a common control (collectively, "Affiliates"), we may in the future. We may share some or all of your Personal Information with these Affiliates, in which case we will require our Affiliates to honor this Privacy Policy.
                            </p>                 
                            <p className='mt-4'>
                                In the event Diligent Dollars goes through a business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets, your Personal Information will likely be among the assets transferred. You acknowledge that such transfers may occur, and that any acquirer of Diligent Dollars or its assets may continue to use your Personal Information as set forth in this Privacy Policy. You hereby consent to Diligent Dollars sharing your Personal Information under the above circumstances.
                            </p>                 
                            <p className='mt-4'>
                                We may disclose information we have collected from and about you (including Personal Information) if we believe in good faith that such disclosure is necessary to (a) comply with relevant laws or to respond to subpoenas or warrants served on us; (b) to enforce our Terms of Use and Privacy Policy; or (c) to protect and defend the rights or property of us, the visitors and Users of our Services, or third parties.
                            </p> 

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Choices Regarding Personal Information</h5>
                            <p>Unsubscribe instructions will accompany each newsletter or promotional communication you receive from us. You may also indicate in your Account preferences that you do not wish to receive newsletters or other promotional communications from Diligent Dollars.</p>
                            <p className='mt-4'>
                                We will send you strictly service-related announcements (such as, but not limited to, notices of any updates to our Diligent Dollars Services, Terms of Use or Privacy Policy) when it is necessary to do so. You may not opt-out of these communications, which are not promotional in nature, but if you do not wish to receive these announcements, you have the option to terminate your account, which involves termination of the Terms of Use. You have the responsibility to delete any information in your Diligent Dollars account that you do not want stored in the Diligent Dollars system following the termination of your Diligent Dollars account; provided however, some of your Personal Information (e.g., name, email address) may remain in the Diligent Dollars system in order to reactivate your account at your request following your account termination or for other archival purposes as described below. Subject to archiving in accordance with applicable law, if you wish not to have any Personal Information remain in the Diligent Dollars system following your account termination, you must provide a written request thereof to Diligent Dollars. Except as otherwise provided herein, you understand that any termination of your Account and the Terms of Use involves deletion of your account information from our active databases and all the information and Data stored for such Account in the Diligent Dollars Services. Diligent Dollars will not have any liability whatsoever to you for any termination of your Account or related deletion of your information or Data.
                            </p>                
                            <p className='mt-4'>
                                Subject to the foregoing, when we delete information that we have collected from or about you, it will be deleted from our active databases but may remain in our archives and subject to the applicable terms of the above-entitled section "Disclosure of Personal Information" (for example, complying with relevant laws or to respond to subpoenas or warrants). Pursuant to this Privacy Policy, Anonymous Information may be actively used by us following the termination of your account.
                            </p>  

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Links to Other Web Sites</h5>
                            <p>The Diligent Dollars Services may contain links to other web sites that are not owned or controlled by Diligent Dollars. The provision of such links is for your convenience and does not signify our endorsement of such web sites or location or its contents. We have no control over, do not review and are not responsible for the privacy policies of or content displayed on such other web sites. Please be aware that the terms of our Privacy Policy do not apply to any outside web sites.</p>
                                         
                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Privacy of Minors</h5>
                            <p>Diligent Dollars complies with the "Children’s Online Privacy Protection Act of 1998". Since the Diligent Dollars Services are intended to be used by all members of your family, Diligent Dollars may collect Personally Identifiable Information about children who are under 13 years old ("Minors"). However, Diligent Dollars does not intentionally collect such Personal Identifiable Information from Minors, but from Users of sufficient legal age that are authorized to provide such information about the respective Minors. As such, you (as the parent or guardian) should sit down with your Minor to help in choosing and to enter such password. By using the Diligent Dollars Services as a parent or guardian, you represent and warrant that you have sufficient legal authority to act on behalf of and represent any Minor you may add to the Account and you hereby expressly consent to the collection of information about these Minors as described herein. There are Diligent Dollars features, e.g., "Checklists", which allow Minors to enter free form information. As a parent or guardian, you consent to Diligent Dollars gathering such free form information, knowing that the Minor may inadvertently include Personal Information. Parents/guardians will be able to see what information about their Minors is stored by the Services in the respective Family Member Account (with the exception of passwords, which will not be displayed) and will be able to delete and/or modify such information at any time (including passwords).</p>
                        

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Security and Confidentiality</h5>
                            <p>Diligent Dollars has put in place commercially reasonable and appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online. However, no company, including Diligent Dollars, can fully eliminate security risks associated with Personal Information.</p>
                        

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Changes in this Privacy Statement</h5>
                            <p>This Privacy Statement is a living document. As part of the Diligent Dollars Terms of Use, this Privacy Policy is subject to occasional amendment, in accordance with the terms of the Terms of Use.</p>
                        

                            <h5 className='fs-4 fw-bold mt-5 pt-4'>Changes in this Privacy Statement</h5>
                            <p>If you have any questions or suggestions regarding our Privacy Policy please <a href="/contact-us">contact us.</a></p>
                        </div>
                    </div>

                    {/* <!-- FOOTER SECTION --> */}
                    <footer className="footer">
                            <div className="content-wrap">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-center">
                                            <div className="footer-item">
                                                <img src={logofooterImg} alt="logo bottom" className="logo-bottom" />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Contact Info
                                                </div>
                                                <ul className="list-info">
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-map-marker"></span>
                                                        </div>
                                                        <div className="info-text">1549 NE 123th Street. North Miami. FL 33161</div>
                                                    </li>
                                                    {/* <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-phone"></span>
                                                        </div>
                                                        <div className="info-text">+1 (786) 873-1002</div>
                                                    </li> */}
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-envelope"></span>
                                                        </div>
                                                        <div className="info-text">help@diligentdollars.com</div>
                                                    </li>
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-clock-o"></span>
                                                        </div>
                                                        <div className="info-text">Mon - Sat 09:00 - 17:00</div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Useful Links
                                                </div>

                                                <ul className="list">
                                                    <li><a href="#" title="About us">About us</a></li>
                                                    <li><a href="#" title="Login" onClick={() => { window.open('/auth/login?tab=0', '_blank'); localStorage.setItem('tab', '0'); }}>Login</a></li>
                                                    <li><a href="#" title="Contact Us" onClick={() => window.open('/contact-us', '_blank')}>Contact Us</a></li>
                                                    <li><a href="#" title="Discover More">Discover More</a></li>
                                                    <li><a href="#" title="Privacy Policy" onClick={() => window.open('/privacy-policy', '_blank')}>Privacy Policy</a></li>
                                                    {/* <li><a href="#" title="Contact Us">Download App</a></li> */}
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Get in Touch
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </footer>
                        {/* <!-- fin footer --> */}

                </Page>
            </PageWrapper>
        </Fragment>
    )
}

export default Privacy;