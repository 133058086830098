import React, { Fragment, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useNavigate } from 'react-router-dom';
import ddLogo from '../../../assets/img/landingpageImage/logo.png';
import { t } from 'i18next';
import iconSign from '../../../assets/img/landingpageImage/icon-sign.png';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import contactImg from '../../../assets/img/landingpageImage/contact.png';
import logofooterImg from '../../../assets/img/landingpageImage/logo-footer.png';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { useFormik, FormikProvider } from 'formik';
import { iContactus } from '../../../common/interface/iContactus';
import * as Yup from 'yup';
import { emailRegex } from '../../../common/default/regex';
import { contactUS } from '../../../common/service/userService';
import { toastAlert, TOAST_TYPE } from '../../../common/functions/displaySwal';
import { allowMobileNumberWithDashesRegex } from '../../../common/default/regex';
const formFields: iContactus = {
    customer_name: '',
    email: '',
    phone: '',
    subject_section: '',
    description: ''
}

const ContactUs = (props: any) => {
    const navigate = useNavigate();
    const [stickyHeader, setStickyHeader] = useState("");
    const navbarClasses = `navbar-main pb-4 pb-lg-0 ${stickyHeader}`;
    const [isVisible, setIsVisible] = useState(false);
    const [formData, setFormdata] = useState<any>(formFields)
    // const sectionStyle = {
    //     backgroundImage: `url(${contactImg})`
    // };
    const formikObj = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            email: Yup.string()
                .required(t('required') || '')
                .email(t('invalidEmail') || '')
                .matches(emailRegex),
        }),
        onSubmit: (values) => {
            // // Handle form submission logic here
            // console.log('Form submitted:', values);
            // // For example, you might call an API here
            if (formikObj.values.email.match(emailRegex)) {
                contactUS(values)?.then((res: any) => {
                    console.log('res', res);
                    if (res?.success == 1) {
                        toastAlert(TOAST_TYPE.SUCCESS, res?.message)
                    } else {
                        toastAlert(TOAST_TYPE.ERROR, res?.message);
                    }

                }).catch((err: any) => {

                }).finally(() => {
                    resetForm();
                })
            }

        },
    });
    const resetForm = () => {
        formikObj.resetForm();
    }
    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyHeaderClass = scrollTop >= 300 ? "stiky" : "";
        setStickyHeader(stickyHeaderClass);
    };
    // Top: 0 takes us all the way back to the top of the page
    // Behavior: smooth keeps it smooth!
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        // document.getElementsByClassName('header')[0].classList.add("d-none");
        window.addEventListener("scroll", isSticky);

        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", isSticky);
            window.removeEventListener("scroll", toggleVisibility);
        };

    }, [])


    return (
        <Fragment>
            <PageWrapper className='p-0 landingpageWrap' title={t('contactUs') || ''}>
                <Page className='p-0'>
                    {/* HEADER  */}
                    <div className="header header-1 m-0 p-0">

                        {/* TOPBAR  */}
                        <div className="topbar">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-sm-8 col-md-6">
                                        <div className="info">
                                            {/* <div className="info-item">
                                                <i className="fa fa-phone"></i> +1 (786) 873-1002
                                            </div> */}
                                            <div className="info-item">
                                                <i className="fa fa-envelope-o"></i> <a href="mailto:support@kids.com" title="">help@diligentdollars.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-6">
                                        <div className="sosmed-icon pull-right d-inline-flex">
                                            <a href="#" className="fb"><i className="fa fa-facebook"></i></a>
                                            <a href="#" className="tw"><i className="fa fa-twitter"></i></a>
                                            <a href="#" className="ig"><i className="fa fa-instagram"></i></a>
                                            <a href="#" className="in"><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* NAVBAR SECTION */}
                        <div className={navbarClasses}>
                            <div className="container">
                                <nav id="navbar-example" className="navbar navbar-expand-lg d-flex justify-content-between">
                                    <a className="navbar-brand" href="index.php">
                                        <img src={ddLogo} alt="" />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">

                                        <div className="d-flex">
                                            <a href="#" className="button text-white d-inline-flex align-items-center bg-tertiary rounded-pill me-2">
                                                <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512">
                                                        <path fill="#ffffff" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                                    </svg>
                                                </span>
                                                <a href="#" rel="noreferrer" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                    onClick={() => { localStorage.setItem('tab', '0'); window.open('/auth/login?tab=0', '_blank') }}
                                                >
                                                    {t('login')}
                                                </a>
                                            </a>
                                        </div>

                                        <div className="d-flex">
                                            <a href="#" className="button text-white d-inline-flex align-items-center bg-primary rounded-pill">
                                                <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                    <img className="me-2 img-fluid" src={iconSign} alt="Imagen" />
                                                </span>
                                                <a href="javascript:void(0);" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                    onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                    {t('signUp')}
                                                </a>
                                            </a>
                                        </div>
                                    </div>
                                </nav>

                            </div>
                        </div>

                    </div>
                    {/* BACK TO TOP SECTION  */}
                    {isVisible && (<a className="cd-top cd-is-visible cd-fade-out cursor-pointer" onClick={scrollToTop}>Top</a>)}

                    {/* Contact Us  */}
                    <div className="contact-us">
                        <div className='container'>
                            <div className='d-flex'>
                                <div className='w-50 pe-5'>
                                    <div className='shadow p-4 border rounded bg-secondary'>
                                        <h3 className='color-primary mt-0 mb-4 fs-2'>Contact Us</h3>
                                        <FormikProvider value={formikObj}>
                                            <FormGroup className='mb-3'>
                                                <Input
                                                    type='text'
                                                    placeholder={t('FnameAndLname') || ''}
                                                    className='mb-4'
                                                    name='customer_name'
                                                    value={formikObj?.values?.customer_name}
                                                    onChange={formikObj?.handleChange}
                                                />
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Input
                                                        type='email'
                                                        placeholder={t('yourEmail') + "*" || ''}
                                                        className='mb-4'
                                                        name='email'
                                                        value={formikObj.values.email}
                                                        onChange={(e: any) => {
                                                            formikObj.handleChange(e)
                                                            formikObj.setFieldError('email', '');
                                                        }}
                                                        onBlur={formikObj.handleBlur}
                                                    />
                                                    <span style={{ color: 'red', marginLeft: '4px', marginBottom: 10 }}></span> {/* Red asterisk */}
                                                </div>
                                                {formikObj.touched.email && formikObj.errors.email ? (
                                                    <span className="text-danger m-2">{t('notValidEmail')}</span>
                                                ) : (
                                                    formikObj.touched.email ? (
                                                        <span style={{ visibility: 'hidden' }}>.</span> // Ensures it always returns a valid ReactElement
                                                    ) : (
                                                        <span style={{ visibility: 'hidden' }}>.</span> // Ensures it always returns a valid ReactElement
                                                    )
                                                )}
                                                <Input
                                                    type='text'
                                                    placeholder={t('phoneNumber') || ''}
                                                    className='mb-4'
                                                    name='phone'
                                                    onChange={(e: any) => {
                                                        formikObj.setFieldValue('phone', allowMobileNumberWithDashesRegex(e.target.value))
                                                    }}
                                                    value={formikObj?.values?.phone}
                                                />
                                                <Select
                                                    ariaLabel='Subject Selection'
                                                    id='subject-selection'
                                                    placeholder={t('subject') || ''}
                                                    className='mb-4'
                                                    name="subject_section"
                                                    value={formikObj?.values?.subject_section}
                                                    onChange={formikObj?.handleChange}
                                                >
                                                    <Option>{(t('Select'))}</Option>
                                                    <Option value="1">ab</Option>
                                                    <Option value="2">mp</Option>
                                                </Select>

                                                <Textarea
                                                    className='mb-2'
                                                    placeholder={t('description') || ''}
                                                    name="description"
                                                    value={formikObj?.values?.description}
                                                    onChange={formikObj?.handleChange}
                                                ></Textarea>
                                                <small className='fs-6 text-dark'>{t('disclaimar') || ''}</small>

                                                <div className='d-flex justify-content-center mt-5'> {/* Flex container to center the button */}
                                                    <Button
                                                        type="button"
                                                        className="btn btn-primary btn-sm font-weight-extrabold py-2 px-2 w-auto"
                                                        color='info'
                                                        onClick={formikObj?.handleSubmit}
                                                    // isDisable={!formikObj.values.email.match(emailRegex) || !formikObj.isValid}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </FormikProvider>
                                    </div>

                                </div>
                                <div className='w-50'>
                                    <img src={contactImg} alt="contact-us" height={400} className='d-block ms-auto' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- FOOTER SECTION --> */}
                    <footer className="footer">
                        <div className="content-wrap">
                            <div className="container">

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-center">
                                        <div className="footer-item">
                                            <img src={logofooterImg} alt="logo bottom" className="logo-bottom" />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <div className="footer-item">
                                            <div className="footer-title">
                                                Contact Info
                                            </div>
                                            <ul className="list-info">
                                                <li>
                                                    <div className="info-icon">
                                                        <span className="fa fa-map-marker"></span>
                                                    </div>
                                                    <div className="info-text">1549 NE 123th Street. North Miami. FL 33161</div>
                                                </li>
                                                {/* <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-phone"></span>
                                                        </div>
                                                        <div className="info-text">+1 (786) 873-1002</div>
                                                    </li> */}
                                                <li>
                                                    <div className="info-icon">
                                                        <span className="fa fa-envelope"></span>
                                                    </div>
                                                    <div className="info-text">help@diligentdollars.com</div>
                                                </li>
                                                <li>
                                                    <div className="info-icon">
                                                        <span className="fa fa-clock-o"></span>
                                                    </div>
                                                    <div className="info-text">Mon - Sat 09:00 - 17:00</div>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <div className="footer-item">
                                            <div className="footer-title">
                                                Useful Links
                                            </div>

                                            <ul className="list">
                                                <li><a href="#" title="About us">About us</a></li>
                                                <li><a href="#" title="Login" onClick={() => { window.open('/auth/login?tab=0', '_blank'); localStorage.setItem('tab', '0'); }}>Login</a></li>
                                                <li><a href="#" title="Contact Us">Contact Us</a></li>
                                                <li><a href="#" title="Discover More">Discover More</a></li>
                                                <li><a href="#" title="Privacy Policy" onClick={() => window.open('/privacy-policy', '_blank')}>Privacy Policy</a></li>
                                                {/* <li><a href="#" title="Contact Us">Download App</a></li> */}
                                            </ul>

                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <div className="footer-item">
                                            <div className="footer-title">
                                                Get in Touch
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </footer>
                    {/* <!-- fin footer --> */}

                </Page>
            </PageWrapper>
        </Fragment >
    )
}

export default ContactUs;