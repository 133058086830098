import { ENDPOINT, axiosInstance } from "../../api";
import { IProfileSetupFormFields } from "../interface/iProfileSetup";

export const addUserProfileService = async (req: any) => {
    const res = await axiosInstance.post(ENDPOINT.SETUP.ADD_AND_UPDATE_USER, req);
    return res;
}
export const fetchUserListService = async () => {
    const res = await axiosInstance.post(ENDPOINT.SETUP.FETCH_USER_LIST);
    return res;
}
export const fetchAllParentListService = async () => {
    const res = await axiosInstance.post(ENDPOINT.SETUP.FETCH_ALL_PARENT_LIST);
    return res;
}
export const deleteParent = async (req:any) => {
    const res = await axiosInstance.post(ENDPOINT.PARENT_DASHBOARD_SETUP.DELETE_PARENT,req);
    return res;
}
export const contactUS = async (req:any) => {
    const res = await axiosInstance.post(ENDPOINT.FOOTER_LINKS.CONTACT_US,req);
    return res;
}
