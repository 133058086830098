import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import '../../../styles/homeStyle.scss';
import iconSign from '../../../assets/img/landingpageImage/icon-sign.png';
import ddLogo from '../../../assets/img/landingpageImage/logo.png';
import sliderimg from '../../../assets/img/landingpageImage/Img-slider.png';
import choreImg from '../../../assets/img/landingpageImage/chores.png';
import moneyImg from '../../../assets/img/landingpageImage/money.png';
import bulbImg from '../../../assets/img/landingpageImage/bulb.png';
import chanchoImg from '../../../assets/img/landingpageImage/chancho.png';
import pesoImg from '../../../assets/img/landingpageImage/img-peso.png';
import leftImg from '../../../assets/img/landingpageImage/img-left.png';
import spendImg from '../../../assets/img/landingpageImage/img-spend.png';
import saveImg from '../../../assets/img/landingpageImage/img-save.png';
import investImg from '../../../assets/img/landingpageImage/img-invest.png';
import relojImg from '../../../assets/img/landingpageImage/img-reloj.png';
import donateImg from '../../../assets/img/landingpageImage/img-donate.png';
import turnImg from '../../../assets/img/landingpageImage/img-turn-.png';
import academicImg from '../../../assets/img/landingpageImage/img-academics.png';
import learningImg from '../../../assets/img/landingpageImage/img-learning.png';
import fitnessImg from '../../../assets/img/landingpageImage/img-fitness.png';
import logofooterImg from '../../../assets/img/landingpageImage/logo-footer.png';
import growthImg from '../../../assets/img/landingpageImage/img-growth.jpg';
import dummyImg from '../../../assets/img/landingpageImage/dummy-img-400x400.jpg';
import signIcon from '../../../assets/img/landingpageImage/icon-sign.png';
import inviteIcon from '../../../assets/img/landingpageImage/icon-invite.png';
import howIcon from '../../../assets/img/landingpageImage/icon-how.png';
import rewardIcon from '../../../assets/img/landingpageImage/icon-reward.png';
import commonIcon from '../../../assets/img/landingpageImage/icon-common.png';
import cashImg from '../../../assets/img/landingpageImage/cash-parent.png';
import cashRewardImg from '../../../assets/img/landingpageImage/cash-reward.png';
import savingRewardImg from '../../../assets/img/landingpageImage/saving-reward.png';
import retailersRewardImg from '../../../assets/img/landingpageImage/retailers-reward.png';
import stocksRewardImg from '../../../assets/img/landingpageImage/stocks-reward.png';
import doingChoreImg from '../../../assets/img/landingpageImage/doing-chore.png';
import doneChoreImg from '../../../assets/img/landingpageImage/done-chore.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { IParentInvite } from '../../../common/interface/iAuth';
import { FieldArray, Form, Formik, FormikProvider, useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { displaySwal } from '../../../common/functions/displaySwal';
import { inviteMyParentService } from '../../../common/service/authService';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import { string } from 'prop-types';

const inviteParentForm: IParentInvite = {
    child_name: '',
    parent_list: [{
        parent_name: '',
        parent_email: ''
    }]
}

const Landing = (props: any) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [stickyHeader, setStickyHeader] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [retailersState, setRetailersState] = useState<boolean>(false);
    const [howItWorksState, setHowItWorksState] = useState<boolean>(false);
    const [rewardOptionsState, setRewardOptionsState] = useState<boolean>(false);
    const [inviteParentFormFields, setInviteParentFormFields] = useState({ ...inviteParentForm });
    const [isLoading, setIsLoading] = useState(false);
    const [navbarCollapseState, setNavbarCollapseState] = useState<boolean>(false);

    const sectionStyle = {
        backgroundImage: `url(${growthImg})`
    };
    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyHeaderClass = scrollTop >= 300 ? "stiky" : "";
        setStickyHeader(stickyHeaderClass);
    };
    const navbarClasses = `navbar-main ${stickyHeader}`;

    // Top: 0 takes us all the way back to the top of the page
    // Behavior: smooth keeps it smooth!
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleRetailerModal = (status: boolean) => {
        setRetailersState(status);
    };

    const handleHowItWorksModal = (status: boolean) => {
        setHowItWorksState(status);
    };

    const handleRewardOptionsModal = (status: boolean) => {
        setRewardOptionsState(status);
    };

    const navbarToggler = (status: boolean) => {
        setNavbarCollapseState(!status);
    };

    useEffect(() => {
        // document.getElementsByClassName('header')[0].classList.add("d-none");
        window.addEventListener("scroll", isSticky);

        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", isSticky);
            window.removeEventListener("scroll", toggleVisibility);
        };

    }, [])
    parent_list: [{
        parent_name: '',
        parent_email: ''
    }]
    const successTitle = t('GenericMessage.success');
    const errorTitle = t('GenericMessage.sorry');
    const errorText = t('somethingWentWrong');
    const confirmButtonText = t('GenericMessage.ok');

    const formikInviteParent = useFormik({
        enableReinitialize: true,
        initialValues: inviteParentFormFields,
        validationSchema: Yup.object({
            child_name: Yup.string().required(t('nameIsRequired') || ''),
            parent_list: Yup.array().of(Yup.object({
                parent_name: Yup.string().required(t('parentNameIsRequired') || ''),
                parent_email: Yup.string().required(t('emailRequired') || '').email(t('notValidEmail') || '')
            }))


        }),
        onSubmit: (values) => {
            setIsLoading(true);
            const filteredValue = values?.parent_list?.map((i: any) => {
                return { ...i, parent_email: i?.parent_email?.toLowerCase() }
            })
            const sendData = { ...values, parent_list: filteredValue }
            inviteMyParentService(sendData).then((res: any) => {
                if (res?.success == 1) {
                    displaySwal(successTitle, res?.message, res?.success, confirmButtonText);
                }
                else {
                    displaySwal(errorTitle, res?.message, res?.success, confirmButtonText);
                }
            })
                .catch((err: Error) => {
                    displaySwal(errorTitle, errorText, 0, confirmButtonText);
                })
                .finally(() => {
                    setIsLoading(false);
                    formikInviteParent.resetForm();
                    formikInviteParent.handleReset;
                })
        }
    })
    const errors = formikInviteParent.errors as { parent_list?: Array<{ parent_name?: string; parent_email?: string }> };
    return (
        <Fragment>
            <PageWrapper className='p-0 landingpageWrap'
                isProtected={false}
                title={'Home'}>
                <Page className='p-0 container-fluid'>
                    <div>

                        {/* HEADER  */}
                        <div className="header header-1 m-0 p-0">

                            {/* TOPBAR  */}
                            <div className="topbar">
                                <div className="container">
                                    <div className="row align-items-center text-center text-sm-start">
                                        <div className="col-sm-8 col-md-6">
                                            <div className="info mb-3 mb-sm-0">
                                                {/* <div className="info-item">
                                                    <i className="fa fa-phone"></i> +1 (786) 873-1002
                                                </div> */}
                                                <div className="info-item">
                                                    <i className="fa fa-envelope-o"></i> <a href="mailto:support@kids.com" title="">help@diligentdollars.com</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-6 text-sm-end">
                                            <div className="sosmed-icon d-inline-flex">
                                                <a href="#" className="fb"><i className="fa fa-facebook"></i></a>
                                                <a href="#" className="tw"><i className="fa fa-twitter"></i></a>
                                                <a href="#" className="ig"><i className="fa fa-instagram"></i></a>
                                                <a href="#" className="in"><i className="fa fa-linkedin"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* NAVBAR SECTION */}
                            <div className={navbarClasses}>
                                <div className="container">
                                    <nav id="navbar-example" className="navbar navbar-expand-md d-flex justify-content-between">
                                        <a className="navbar-brand" href="index.php">
                                            <img src={ddLogo} alt="" />
                                        </a>
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => navbarToggler(navbarCollapseState)}>
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="navbar-collapse justify-content-md-end py-4 mb-py-0 d-md-flex justify-content-center" id="navbarNavDropdown" style={{ display: navbarCollapseState ? "flex" : "none" }}>

                                            <div className="d-flex">
                                                <a href="#" className="button text-white d-inline-flex align-items-center bg-tertiary rounded-pill me-2">
                                                    <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512">
                                                            <path fill="#ffffff" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                                        </svg>
                                                    </span>
                                                    <a href="#" rel="noreferrer" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                        onClick={() => { localStorage.setItem('tab', '0'); window.open('/auth/login?tab=0', '_blank') }}
                                                    >
                                                        {t('login')}
                                                    </a>
                                                </a>
                                            </div>

                                            <div className="d-flex">
                                                <a href="#" className="button text-white d-inline-flex align-items-center bg-primary rounded-pill">
                                                    <span className="text-decoration-none chat d-flex justify-content-center align-items-center text-white bg-secondary">
                                                        <img className="me-2 img-fluid" src={iconSign} alt="Imagen" />
                                                    </span>
                                                    <a href="javascript:void(0);" className="phone py-2 px-4 text-decoration-none text-white d-flex align-items-center text-uppercase font-weight-bold"
                                                        onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                        {t('signUp')}
                                                    </a>
                                                </a>
                                            </div>
                                        </div>
                                       
                                    </nav>

                                </div>
                            </div>

                        </div>

                        {/* BACK TO TOP SECTION  */}
                        {isVisible && (<a className="cd-top cd-is-visible cd-fade-out cursor-pointer" onClick={scrollToTop}>Top</a>)}


                        {/* HOME HERO  */}
                        <section className="homeHeroSection">
                            <div className='carousel'>
                                <OwlCarousel items={1}
                                    className="owl-theme"
                                    loop
                                    nav={false}
                                    dots={false}
                                    autoplay={false}
                                    autoplayTimeout={8000}
                                    margin={0} >
                                    <div >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-7 col-xl-6">
                                                    <div className="b-contain mt-5">
                                                        <h1 className="caption-supheading"><span className="font-secondary color-secondary">Fun</span> <strong><span className="font-primary color-primary">for the <br /> entire</span></strong> <span className="font-secondary color-primary">family</span></h1>
                                                        <p className="font-primary mt-2 font-quaternary font-weight-bold"><strong>Ignite the imagination and curiosity of young minds in a safe online environment. Diligent Dollars is not just another app; it's a digital playground where learning meets fun, where children can embark on exciting adventures while parents can have peace of mind.</strong></p>
                                                        <a className="btn btn-primary btn-sm font-quaternary font-weight-bold me-2 px-4 mb-3 mb-sm-0" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                            <svg className="me-3" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path fill="#ffffff" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>Join Now
                                                        </a>

                                                        <a href="javascript:void(0);" onClick={() => handleHowItWorksModal(true)} className="btn btn-tertiary btn-sm font-quaternary font-weight-bold me-2 d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0">
                                                            <img className="me-3 img-fluid" src={howIcon} alt="Imagen" style={{ width: '30px' }} />How It Works
                                                        </a>
                                                        <a href="#" className="btn btn-quaternary btn-sm font-quaternary font-weight-bold d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0"
                                                            onClick={() => { window.open('/auth/login?tab=2', '_blank'); localStorage.setItem('tab', '2'); }} style={{height: '56px'}}>
                                                            <img className="me-3 img-fluid d-inline" src={inviteIcon} alt="Imagen" style={{ width: '30px' }} />Invite
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-xl-6">
                                                    <div className="b-img">
                                                        <img className="img-fluid" src={sliderimg} alt="Introduction" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-7 col-xl-6">
                                                    <div className="b-contain mt-5">
                                                        <h1 className="caption-supheading"><span className="font-secondary color-secondary">Fun</span> <strong><span className="font-primary color-primary">for the <br /> entire</span></strong> <span className="font-secondary color-primary">family</span></h1>
                                                        <p className="font-primary mt-2 font-quaternary font-weight-bold"><strong>Ignite the imagination and curiosity of young minds in a safe online environment. Diligent Dollars is not just another app; it's a digital playground where learning meets fun, where children can embark on exciting adventures while parents can have peace of mind.</strong></p>
                                                        <a href="#" className="btn btn-primary btn-sm font-quaternary font-weight-bold me-2 px-4 mb-3 mb-sm-0"
                                                            onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}
                                                        >
                                                            <svg className="me-3" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path fill="#ffffff" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>Join Now
                                                        </a>

                                                        <a href="javascript:void(0);" onClick={() => handleHowItWorksModal(true)} className="btn btn-tertiary btn-sm font-quaternary font-weight-bold me-2 d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0">
                                                            <img className="me-3 img-fluid" src={howIcon} alt="Imagen" style={{ width: '30px' }} />How It Works
                                                        </a>
                                                        <a href="#" className="btn btn-quaternary btn-sm font-quaternary font-weight-bold d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0"
                                                            onClick={() => { window.open('/auth/login?tab=2', '_blank'); localStorage.setItem('tab', '2'); }} style={{height: '56px'}}
                                                        >
                                                            <img className="me-3 img-fluid d-inline" src={inviteIcon} alt="Imagen" style={{ width: '30px' }} />Invite
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-xl-6">
                                                    <div className="b-img">
                                                        <img className="img-fluid" src={sliderimg} alt="Introduction" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-7 col-xl-6">
                                                    <div className="b-contain mt-5">
                                                        <h1 className="caption-supheading"><span className="font-secondary color-secondary">Fun</span> <strong><span className="font-primary color-primary">for the <br /> entire</span></strong> <span className="font-secondary color-primary">family</span></h1>
                                                        <p className="font-primary mt-2 font-quaternary font-weight-bold"><strong>Ignite the imagination and curiosity of young minds in a safe online environment. Diligent Dollars is not just another app; it's a digital playground where learning meets fun, where children can embark on exciting adventures while parents can have peace of mind.</strong></p>
                                                        <a href="#" className="btn btn-primary btn-sm font-quaternary font-weight-bold me-2 px-4 mb-3 mb-sm-0" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}
                                                        >
                                                            <svg className="me-3" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path fill="#ffffff" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>Join Now
                                                        </a>

                                                        <a href="javascript:void(0);" onClick={() => handleHowItWorksModal(true)} className="btn btn-tertiary btn-sm font-quaternary font-weight-bold me-2 d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0">
                                                            <img className="me-3 img-fluid" src={howIcon} alt="Imagen" style={{ width: '30px' }} />How It Works
                                                        </a>
                                                        <a href="#" className="btn btn-quaternary btn-sm font-quaternary font-weight-bold d-inline-flex align-items-center justify-content-center px-4 mb-3 mb-sm-0"
                                                            onClick={() => { window.open('/auth/login?tab=2', '_blank'); localStorage.setItem('tab', '2'); }} style={{height: '56px'}}>
                                                            <img className="me-3 img-fluid d-inline" src={inviteIcon} alt="Imagen" style={{ width: '30px' }} />Invite
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-xl-6">
                                                    <div className="b-img">
                                                        <img className="img-fluid" src={sliderimg} alt="Introduction" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </section>

                        {/* SERVICES  */}
                        <div className="section services">
                            <div className="content-wrap">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="row col-0 overlap no-gutters">
                                                {/* <!-- BOX 1 --> */}
                                                <div className="col-sm-12 col-lg-4">
                                                    <div className="flip-card-inner rs-feature-box-1 bg-primary">
                                                        <div className="front">
                                                            <span className="fa"><img src={choreImg} alt="Imagen" /></span>

                                                            <div className="body">
                                                                <h4 className="font-quaternary font-weight-extrabold">Earn</h4>
                                                                <p>Kids earn money by doing  chores or <br /> fitness exercise.</p>
                                                                <div className="spacer-10"></div>
                                                                <button className="btn btn-secondary font-weight-extrabold">HOW TO EARN</button>
                                                            </div>
                                                        </div>
                                                        <div className='back bg-primary'>
                                                            <span className="fa"><img src={choreImg} alt="Imagen" /></span>
                                                            <p>Every chore completed is a step towards fun rewards and learning valuable skills. Start earning today and see how quickly your efforts pay off!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- BOX 2 --> */}
                                                <div className="col-sm-12 col-lg-4">
                                                    <div className="flip-card-inner rs-feature-box-1 bg-secondary">
                                                        <div className="front">
                                                            <span className="fa bg-secondary"><img src={moneyImg} alt="Imagen" /></span>

                                                            <div className="body">
                                                                <h4 className="font-quaternary font-weight-extrabold">Spend</h4>
                                                                <p>Kids convert their Diligent Dollars <br /> to Real Rewards.</p>
                                                                <div className="spacer-10"></div>
                                                                <button className="btn btn-quaternary font-weight-extrabold">SEE REWARDS</button>
                                                            </div>
                                                        </div>
                                                        <div className='back bg-secondary'>
                                                            <span className="fa bg-secondary"><img src={moneyImg} alt="Imagen" /></span>
                                                            <p>Hard work pays off in a big way! As you complete chores and earn your Diligent Dollars, you can look forward to spending them on exciting rewards. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- BOX 3 --> */}
                                                <div className="col-sm-12 col-lg-4">
                                                    <div className="flip-card-inner rs-feature-box-1 bg-tertiary">
                                                        <div className="front">
                                                            <span className="fa"><img src={bulbImg} alt="Imagen" /></span>

                                                            <div className="body">
                                                                <h4 className="font-quaternary font-weight-extrabold">Save</h4>
                                                                <p>Save on cleaning and home <br />maintenance.</p>
                                                                <div className="spacer-10"></div>
                                                                <button className="btn btn-primary font-weight-extrabold">HOW TO SAVE</button>
                                                            </div>
                                                        </div>
                                                        <div className='back bg-tertiary'>
                                                            <span className="fa"><img src={bulbImg} alt="Imagen" /></span>
                                                            <p>By saving Diligent Dollars, kids learn valuable skills and experience the joy of achieving bigger dreams. Start saving today and see how your efforts add up!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- BOX 4 --> */}
                                                {/* <div className="col-sm-12 col-lg-3">
                                                    <div className="rs-feature-box-1 bg-quaternary">
                                                        <span className="fa bg-quaternary"><img src={chanchoImg} alt="Imagen"/></span>
                                                        
                                                        <div className="body">
                                                            <h4 className="font-quaternary font-weight-extrabold">Invest</h4>
                                                            <p>Kids can use their Diligent Dollars to earn for their future.</p>
                                                            <div className="spacer-10"></div>
                                                            <a href="#" className="btn btn-secondary font-weight-extrabold">HOW IT WORKS</a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- SECTION IMAGE LEFT --> */}
                        <section className="section_image_left position-relative mb-4 lg-md-0 text-center text-lg-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img className="img-fluid section_image_left_position" src={leftImg} alt="Imagen" style={{ height: '500px' }} />
                                    </div>
                                    <div className="col-lg-5 pt-5 ps-3">
                                        <h2 className="color-tertiary mb-0 mt-5">Complete <span className="font-secondary color-primary">your chores</span> and</h2>
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                            <h3 className="color-tertiary my-0 title_lg me-3 mb-4">Earn</h3>
                                            <div>
                                                <img className="img-fluid" src={pesoImg} alt="" />
                                            </div>
                                        </div>
                                        <p className="font-weight-semibold">Let your kids <span className="color-tertiary">earn $$$ for completing household <br /> chores.</span> Download the app start earning you own <br /> money and enjoy you chores.</p>
                                        <div className="buttons d-inline-flex d-md-flex flex-column flex-md-row justify-content-center justify-content-lg-start">
                                            <a href="#" className="btn btn-primary me-0 px-3 py-2 fs-5 fw-bolder me-md-2 mb-3 mb-md-0" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                <img className="me-2 img-fluid" src={signIcon} alt="Imagen" /> Sign Up</a>
                                            {/* <a href="#" className="btn btn-tertiary me-0 px-3 py-2 fs-5 fw-bolder me-md-2 mb-2 mb-md-0"><i className="fa fa-download d-inline-block me-2"></i> Download App</a> */}
                                            <a href="#" className="btn btn-quaternary px-3 py-2 fs-5 fw-bolder" onClick={() => { window.open('/auth/login?tab=2', '_blank'); localStorage.setItem('tab', '2'); }}><img className="me-2 img-fluid" src={inviteIcon} alt="Imagen" /> Invite</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 pt-5 ps-lg-3 mt-5 mb-5 mb-lg-0">
                                        <div className='shadow p-3 border rounded'>
                                            <h3 className='text-center color-primary mt-0 mb-2 fs-4'>{t('inviteMyParents')}</h3>
                                            <FormikProvider value={formikInviteParent}>
                                                <Form>
                                                    <Input
                                                        type='text'
                                                        placeholder={t('myName') || ''}
                                                        className='mb-3'
                                                        required
                                                        autoComplete='new-password'
                                                        name='child_name'
                                                        value={formikInviteParent.values.child_name}
                                                        isValid={formikInviteParent.isValid}
                                                        onChange={formikInviteParent.handleChange}
                                                        onBlur={formikInviteParent.handleBlur}
                                                        onFocus={() => {
                                                            formikInviteParent.setErrors({});
                                                        }}
                                                    />
                                                    {formikInviteParent.touched.child_name && formikInviteParent.errors.child_name ? (
                                                        <div className="text-danger">{formikInviteParent.errors.child_name}</div>
                                                    ) : null}

                                                    <FieldArray
                                                        name='parent_list'
                                                        render={arrayhelpers => (
                                                            <Fragment>
                                                                {formikInviteParent.values.parent_list.map((field: any, index: number) => {
                                                                    const isLastRow = formikInviteParent.values?.parent_list?.length - 1 === index;
                                                                    const getFieldName = (name: any) => `${arrayhelpers.name}[${index}][${name}]`;
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <Input
                                                                                type='text'
                                                                                placeholder={t('parentName') || ''}
                                                                                className='mb-3'
                                                                                required
                                                                                autoComplete='new-password'
                                                                                name={getFieldName('parent_name')}
                                                                                value={field.parent_name}
                                                                                isValid={formikInviteParent.isValid}
                                                                                onChange={formikInviteParent.handleChange}
                                                                                onBlur={formikInviteParent.handleBlur}
                                                                                onFocus={() => {
                                                                                    formikInviteParent.setErrors({});
                                                                                }}
                                                                            />
                                                                            {formikInviteParent.touched.parent_list?.[index]?.parent_name && errors.parent_list?.[index]?.parent_name ? (
                                                                                <div className="text-danger">{errors.parent_list?.[index]?.parent_name}</div>
                                                                            ) : null}
                                                                            <Input
                                                                                type='text'
                                                                                placeholder={t('parentEmail') || ''}
                                                                                className='mb-3'
                                                                                required
                                                                                autoComplete='new-password'
                                                                                name={getFieldName('parent_email')}
                                                                                value={field.parent_email}
                                                                                isValid={formikInviteParent.isValid}
                                                                                onChange={formikInviteParent.handleChange}
                                                                                onBlur={formikInviteParent.handleBlur}
                                                                                onFocus={() => {
                                                                                    formikInviteParent.setErrors({});
                                                                                }}
                                                                            />
                                                                            {formikInviteParent.touched.parent_list?.[index]?.parent_email && errors.parent_list?.[index]?.parent_email ? (
                                                                                <div className="text-danger">{errors.parent_list?.[index]?.parent_email}</div>
                                                                            ) : null}
                                                                        </Fragment>
                                                                    )
                                                                }
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                    <div className='text-center'>
                                                        <Button type="submit" className="btn btn-secondary btn-sm font-weight-extrabold py-2 px-2 w-auto"
                                                            color='info'
                                                            isDisable={isLoading}
                                                            onClick={formikInviteParent.handleSubmit}>
                                                            {isLoading && (
                                                                <Spinner isSmall inButton isGrow />
                                                            )}
                                                            Submit</Button>
                                                    </div>
                                                </Form>
                                            </FormikProvider>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <!-- SECTION IMAGE RIGHT --> */}

                        <section className="section_image_right bg-tertiary section_spend text-center text-lg-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 pt-5">
                                        <h2 className="text-white mb-0 mt-5">Get <span className="font-secondary">your rewards</span> and</h2>
                                        <h3 className="text-white title_lg mt-0 mb-5">Spend</h3>
                                        <p className="text-white font-weight-semibold">Lorem Ipsum is simply dummy text of the printing <br /> and typesetting industry. Lorem Ipsum has been the <br /> industry's standard </p>
                                        <div className="buttons d-inline-flex d-md-flex flex-column flex-md-row justify-content-center justify-content-lg-start">
                                            <a href="javascript:void(0);" onClick={() => handleHowItWorksModal(true)} className="btn btn-secondary me-0 px-3 py-2 fs-5 fw-bolder me-md-2 mb-3 mb-md-0"><img className="me-2 img-fluid" src={howIcon} alt="Imagen" /> How it Works</a>
                                            <a href="javascript:void(0);" onClick={() => handleRewardOptionsModal(true)} className="btn btn-primary me-0 px-3 py-2 fs-5 fw-bolder me-md-2 mb-3 mb-md-0"><img className="me-2 img-fluid" src={rewardIcon} alt="Imagen" /> Reward Options</a>
                                            <a href="javascript:void(0);" onClick={() => handleRetailerModal(true)} className="btn btn btn-quaternary px-3 py-2 fs-5 fw-bolder"><img className="me-2 img-fluid" src={commonIcon} alt="Imagen" /> Common Retailers</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <img className="img-fluid img_top get-reward-img" src={spendImg} alt="Imagen" />
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <!-- SECTION IMAGE LEFT --> */}
                        <section className="section_image_left position-relative mb-4 mb-lg-0 text-center text-lg-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img className="img-fluid section_image_left_position" src={saveImg} alt="Imagen" style={{ height: '500px' }} />
                                    </div>
                                    <div className="col-lg-8 pt-5 pl-4 mt-lg-5 mb-5 mb-lg-0">
                                        <h2 className="color-tertiary mb-0 mt-lg-5">The key is in <span className="font-secondary color-primary">your future</span></h2>
                                        <div className="d-flex align-items-center gap-3 justify-content-center justify-content-lg-start">
                                            <h3 className="color-tertiary my-0 title_lg mb-4" style={{ lineHeight: '55px' }}>Save... Invest... Learn...</h3>
                                        </div>
                                        <p className="font-weight-semibold">Your kids will save big when completing <span className="color-primary">chores or fitness  <br /> activities,</span> and even on their report cards. <br /> Help them learn to save for their futures using<br /> some of our cool tips and tricks</p>
                                        {/* <div className="buttons">
                                            <a href="#" className="btn btn-tertiary me-2 fw-bolder btn-lg"><i className="fa fa-download d-inline-block me-2"></i> Download App</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <!-- SECTION IMAGE RIGHT --> */}
                        <section className="section_image_right bg-secondary pb-5 text-center text-lg-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 pt-5">
                                        <h2 className="text-white mb-0 mt-5">Track <span className="font-secondary color-primary">your Growth </span> with</h2>
                                        <h3 className="color-primary title_lg mt-0 mb-4">Growth Tracker</h3>
                                        <p className="text-white font-weight-semibold">Lorem Ipsum is simply dummy text of the printing <br /> and typesetting industry. Lorem Ipsum has been the <br /> industry's standard </p>
                                        {/* <div className="buttons">
                                            <a href="#" className="btn btn-primary fw-bolder btn-lg"><i className="fa fa-download d-inline-block me-2"></i> Download App</a>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-4 my-5 my-lg-0">
                                        <img className="img-fluid img_top" src={investImg} alt="Imagen" style={{ height: '500px' }} />
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* <!-- SECTION IMAGE LEFT --> */}
                        {/* <section className="section_image_left position-relative mb-4 mb-lg-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img className="img-fluid section_image_left_position" src={donateImg} alt="Imagen" style={{height: '500px'}}/>
                                    </div>
                                    <div className="col-lg-8 pt-5 ps-5 mt-5">
                                        <h2 className="mb-0 mt-5">The importance of <span className="font-secondary color-secondary">sharing</span></h2>
                                        <div className="d-flex align-items-center gap-3">
                                            <h3 className="color-secondary my-0 title_lg mb-5">Donate</h3>
                                        </div>
                                        <p className="font-weight-semibold">Let your kids save for completing household <br/> chores. Download the app start earning you own <br/> money and enjoy you chores.</p>
                                        <div className="buttons">
                                            <a href="#" className="btn btn-secondary me-2 fw-bolder btn-lg"><i className="fa fa-download d-inline-block me-2"></i> Download App</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        {/* <!-- WELCOME TO KIDS --> */}
                        <div className="section pb-5 pt-5 backgound-gray section_grabs">

                            <div className="container">
                                <h2 className="section-heading text-center mt-5">
                                    <span className="font-secondary color-secondary">Try our</span> <span className="font-primary color-primary"></span><strong>UP-FOR-GRABS</strong>
                                </h2>
                                <div className="row justify-content-center">
                                    <div className="col-sm-12 col-md-12 col-lg-3 d-flex justify-content-center justify-content-lg-start">
                                        <img src={relojImg} alt="" className="img-fluid img-border mb-3 mb-lg-0" />
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5 d-flex justify-content-center justify-content-lg-start">
                                        <div>
                                            <p className="font-weight-semibold">Set One-Time Projects and  <br /> let your kids earn even more <br /><span className="text-primary">Diligent Dollars...</span> </p>
                                            <div className="spacer-10"></div>
                                            <div className="buttons">
                                                <a href="#" className="btn btn-primary me-2 fw-bolder btn-lg" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}><i className="fa fa-download d-inline-block me-2"></i> Try it Now!</a>
                                            </div>
                                            <div className="spacer-30"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">

                                    <div className="row justify-content-center">
                                        <div className="col-sm-12 col-lg-4">
                                            {/* <!-- BOX 1 --> */}
                                            <div className='flip-card-inner rs-feature-box-1 bg-primary'>
                                                <div className="front">
                                                    <i className="fa fa-home"></i>
                                                    <div className="body">
                                                        <h4 className="font-secondary fs-1 mt-0 line-height-1">Household & Fitness Chores</h4>
                                                        <p>Get more households & fitness chores done around the house</p>
                                                        <button className="btn btn-secondary text-uppercase">Learn More</button>
                                                    </div>
                                                </div>
                                                <div className='back bg-primary'>
                                                    <i className="fa fa-home"></i>
                                                    <p>From tidying up their rooms to helping set the table, our app offers a variety of age-appropriate household chores. These tasks are designed to instill a sense of duty and pride in maintaining a clean and organized home.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-4">
                                            {/* <!-- BOX 2 --> */}
                                            <div className='flip-card-inner rs-feature-box-1 bg-secondary'>
                                                <div className="front">
                                                    <i className="fa fa-bar-chart bg-secondary"></i>
                                                    <div className="body">
                                                        <h4 className="font-secondary fs-1">Progress Tracker</h4>
                                                        <p>Watch as your progress bar fills up with <br />each task completed</p>
                                                        <button className="btn btn-quaternary text-uppercase">Learn More</button>
                                                    </div>
                                                </div>
                                                <div className='back bg-secondary'>
                                                    <i className="fa fa-bar-chart bg-secondary"></i>
                                                    <p>Watch as your progress bar fills up with each task completed, giving a clear visual of how close you are to your goal. With our Progress Tracker, chores become a journey of growth and accomplishment. Start tracking your progress and see how far you can go!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-4">
                                            {/* <!-- BOX 3 --> */}
                                            <div className='flip-card-inner rs-feature-box-1 bg-tertiary'>
                                                <div className="front">
                                                    <i className="fa fa-heart bg-tertiary"></i>
                                                    <div className="body">
                                                        <h4 className="font-secondary fs-1">Wishlist</h4>
                                                        <p>With the Wishlist Feature, kids learn the value of setting goals and working towards them</p>
                                                        <button className="btn btn-primary text-uppercase">Learn More</button>
                                                    </div>
                                                </div>
                                                <div className='back bg-tertiary'>
                                                    <i className="fa fa-heart bg-tertiary"></i>
                                                    <p>With the Wishlist Feature, kids learn the value of setting goals and working towards them, while parents gain insights into their child’s dreams and desires. Start adding to your wishlist and see your dreams come true, one chore at a time!</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        {/* <!-- SECTION IMAGE RIGHT --> */}

                        <section className="section_image_right bg-secondary section_academics mt-0 text-center text-lg-start">
                            <div className="container">
                                <div className="row pt-5">
                                    <div className="col-lg-8 col_left">
                                        <h2 className="color-primary mb-0 text-uppercase">Academics</h2>
                                        <h3 className="text-white mb-3 mt-0 lh-1">Upload <span className="font-secondary color-primary">your report card</span> and start earning!</h3>
                                        <p className="font-weight-semibold text-white mb-3">A = $10 , B = $8, C = $5 <br /> Immediately see your kids improve in school.
                                            <br />Set the <span className="color-primary">$$ rewards</span> for getting good grades...</p>
                                        {/* <div className="buttons">
                                            <a href="#" className="btn btn-primary fw-bolder btn-lg"><i className="fa fa-download d-inline-block me-2"></i> Download App</a>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-4 position-relative mt-5 mt-lg-0">
                                        <img className="img-fluid img_bottom position-absolute" src={academicImg} alt="Imagen" style={{ height: '300px' }} />
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <!-- SECTION IMAGE LEFT --> */}

                        <section className="section_image_left position-relative section_lerning mb-4 mb-lg-0 text-center text-lg-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <img className="img-fluid section_image_left_position" src={learningImg} alt="Imagen" style={{ height: '550px' }} />
                                    </div>
                                    <div className="col-lg-8 pt-5 pl-5 mt-lg-5 text-end col_right mb-5 mb-lg-0">
                                        <h2 className="color-primary mb-0 text-uppercase pt-5">Learning Center</h2>
                                        <h3 className="mb-0 color-tertiary mt-0 lh-1">You <span className="font-secondary color-primary">can learn</span>  <span className="color-tertiary me-3">a lot!</span>You <span className="font-secondary color-primary">Earn</span> <span className="color-tertiary">a lot!</span></h3>
                                        <p className="font-weight-semiboldfs-5 mb-3">How to properly brush your teeth <span className='mx-3'>|</span> How to clean your butt 😊 <span className='mx-3'>|</span>How to floss</p>

                                        <p className="font-weight-semibold fs-5 text-justify">The Learning Center is more than just an add-on; it's a foundational element of our app that empowers children to become responsible and self-sufficient while having fun. With each lesson learned, they not only earn rewards but also build valuable skills for life.</p>
                                        <div className="buttons">
                                            <a href="#" className="btn btn-primary me-2 fw-bolder btn-lg" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}>
                                                <svg className="me-3" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path fill="#ffffff" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"></path></svg> Start Now!
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <!-- SECTION IMAGE RIGHT --> */}

                        <section className="section_image_right bg-primary section_academics text-center text-lg-start">
                            <div className="container">
                                <div className="row pt-5">
                                    <div className="col-lg-8 col_left">
                                        <h2 className="text-white mb-0 text-uppercase pt-5">Fitness Center</h2>
                                        <h3 className="text-white mb-3 lh-1 mt-0">Get <span className="font-secondary color-secondary">your exercices done</span> and start earning!</h3>
                                        <p className="font-weight-semibold fs-5 text-white mb-lg-5">Lorem Ipsum is simply dummy text of the <br /> printing and typesetting industry. </p>
                                        {/* <div className="buttons">
                                            <a href="#" className="btn btn-secondary fw-bolder btn-lg"><i className="fa fa-download d-inline-block me-2"></i> Download App</a>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-4">
                                        <img className="img-fluid img_top" src={fitnessImg} alt="Imagen" />
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* <!-- OUR ARTICLES --> */}
                        <div className="pt-5 our-article">
                            <div className="container">

                                <h2 className="section-heading text-center mb-0 color-quaternary">
                                    <strong>Turn those</strong>
                                </h2>
                                <h3 className="section-heading text-center font-secondary">
                                    <span className="color-primary">Diligent Dollars into</span><span className="color-secondary"> Rewards</span>
                                </h3>

                                <div className="text-center pt-5 pt-lg-0 mt-5 mt-lg-0">
                                    <img className="img-fluid" src={turnImg} alt="Imagen" />
                                </div>



                                <div className="row mt-4">

                                    {/* <!-- Item 1 --> */}
                                    <div className="col-sm-12 col-md-12 col-lg-4">
                                        <div className="flip-card-inner rs-class-box mb-5">
                                            <div className="front">

                                                <div className="body-box">
                                                    <div className="class text-center">
                                                        <div className="title mb-3">Shop at the most Common <br /> Retailers</div>
                                                    </div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                    </p>
                                                    <div className="detail">
                                                        {/* <a href="#" className="age col text-white"><strong>Login</strong></a> */}
                                                        <button className="size col text-white"><strong>Learn More</strong></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='back justify-content-center p-5  rs-class-box'>
                                                <p className='m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Item 2 --> */}
                                    <div className="col-sm-12 col-md-12 col-lg-4">
                                        <div className="flip-card-inner rs-class-box mb-5">
                                            <div className="front">
                                                <div className="body-box">
                                                    <div className="class-name text-center">
                                                        <div className="title mb-3">Convert to Cash or <br /> Savings</div>
                                                    </div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                    </p>
                                                    <div className="detail">
                                                        {/* <a href="#" className="age col text-white"><strong>Login</strong></a> */}
                                                        <button className="size col text-white"><strong>Learn More</strong></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='back justify-content-center p-5  rs-class-box'>
                                                <p className='m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Item 3 --> */}
                                    <div className="col-sm-12 col-md-12 col-lg-4">
                                        <div className="flip-card-inner rs-class-box mb-5">
                                            <div className="front">

                                                <div className="body-box">
                                                    <div className="class text-center">
                                                        <div className="title mb-3">Investment Accounts and <br /> Stocks </div>
                                                    </div>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                                    <div className="detail">
                                                        {/* <a href="#" className="age col text-white"><strong>Login</strong></a> */}
                                                        <button className="size col text-white"><strong>Learn More</strong></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='back justify-content-center p-5  rs-class-box'>
                                                <p className='m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {/* <!-- WHY CHOOSE US --> */}
                        <div className="section bgi-repeat choose" data-background={growthImg} style={sectionStyle}>
                            <div className="content-wrap pb-0 text-center text-lg-start">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-7">
                                            <h2 className="section-heading font-secondary">
                                                <span className="color-primary">Growth</span> <span className="color-secondary">Tracker</span>
                                            </h2>
                                            <p className="font-weight-semibold text-white">Keep track of your kids growth and weight over the years with our  <br /> <span className="color-secondary">Growth Tracker</span> tool.
                                                Graphs and Animation make this tool super <br /> cool and fun
                                            </p>
                                            <a href="#" className="btn btn-tertiary me-2 fw-bolder btn-lg" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}><i className="fa fa-download d-inline-block me-2"></i> Try it Now!</a>
                                            <div className="spacer-90"></div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-5">
                                            {/* <img src="images/dummy-img-600x700.jpg" alt="" class="img-fluid"/> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <!-- OUR TESTIMONIALS --> */}
                        <div className="section" style={{ backgroundColor: '#e3e3e3' }}>
                            <div className="content-wrap-40">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <h2 className="section-heading text-center mb-5">
                                                <span className="font-secondary">What parents say</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-10 offset-md-1">
                                            <div className="text-center text-secondary mb-3"><i className="fa fa-quote-right fa-3x"></i></div>
                                            <OwlCarousel items={1}
                                                className="owl-theme"
                                                loop
                                                nav={false}
                                                autoplay={true}
                                                autoplayTimeout={8000}
                                                margin={0} >
                                                <div >
                                                    <div className="rs-box-testimony">
                                                        <div className="quote-box">
                                                            <blockquote>
                                                                Teritatis et quasi architecto. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium, totam rem aperiam
                                                            </blockquote>
                                                            <div className="media">
                                                                <img src={dummyImg} alt="" className="rounded-circle" />
                                                            </div>
                                                            <p className="quote-name">
                                                                Johnathan Doel <span>Businessman</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="rs-box-testimony">
                                                        <div className="quote-box">
                                                            <blockquote>
                                                                Teritatis et quasi architecto. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolore mque laudantium, totam rem aperiam
                                                            </blockquote>
                                                            <div className="media">
                                                                <img src={dummyImg} alt="" className="rounded-circle" />
                                                            </div>
                                                            <p className="quote-name">
                                                                Johnathan Doel <span>CEO Buka Kreasi</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- CTA --> */}
                        <div className="section bg-secondary">
                            <div className="content-wrap py-5">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="cta-1 text-center">
                                                <div className="mb-3">
                                                    <h3 className="my-1 text-secondary">
                                                        <span className="font-secondary color-primary">Free</span>
                                                        <span className="font-primary color-quaternary mx-2">
                                                            <strong>For Up to</strong>
                                                        </span>
                                                        <span className="font-secondary color-primary">2 Children and 3 Chores</span>
                                                    </h3>
                                                </div>
                                                <div className="">
                                                    <a href="#" className="btn btn-tertiary me-2 fw-bolder btn-lg" onClick={() => { window.open('/auth/login?tab=1', '_blank'); localStorage.setItem('tab', '1'); }}><i className="fa fa-download d-inline-block me-2"></i> Try it Now!</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- FOOTER SECTION --> */}
                        <footer className="footer">
                            <div className="content-wrap text-center text-lg-start">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 col-xl-3 d-flex align-items-center justify-content-lg-start justify-content-center">
                                            <div className="footer-item">
                                                <img src={logofooterImg} alt="logo bottom" className="logo-bottom" />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-lg-6 col-xl-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Contact Info
                                                </div>
                                                <ul className="list-info text-center text-lg-start">
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-map-marker"></span>
                                                        </div>
                                                        <div className="info-text">1549 NE 123th Street. North Miami. FL 33161</div>
                                                    </li>
                                                    {/* <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-phone"></span>
                                                        </div>
                                                        <div className="info-text">+1 (786) 873-1002</div>
                                                    </li> */}
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-envelope"></span>
                                                        </div>
                                                        <div className="info-text">help@diligentdollars.com</div>
                                                    </li>
                                                    <li>
                                                        <div className="info-icon">
                                                            <span className="fa fa-clock-o"></span>
                                                        </div>
                                                        <div className="info-text">Mon - Sat 09:00 - 17:00</div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-lg-6 col-xl-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Useful Links
                                                </div>

                                                <ul className="list">
                                                    <li><a href="#" title="About us">About us</a></li>
                                                    <li><a href="#" title="Login" onClick={() => { window.open('/auth/login?tab=0', '_blank'); localStorage.setItem('tab', '0'); }}>Login</a></li>
                                                    <li><a href="#" title="Contact Us" onClick={() => window.open('/contact-us', '_blank')}>Contact Us</a></li>
                                                    <li><a href="#" title="Discover More">Discover More</a></li>
                                                    <li><a href="#" title="Privacy Policy" onClick={() => window.open('/privacy-policy', '_blank')}>Privacy Policy</a></li>
                                                    {/* <li><a href="#" title="Contact Us">Download App</a></li> */}
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-lg-6 col-xl-3">
                                            <div className="footer-item">
                                                <div className="footer-title">
                                                    Get in Touch
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </footer>
                        {/* <!-- fin footer --> */}
                    </div>
                </Page>
            </PageWrapper>
            {/************************************* *
					Start Retailer list Modal 
			*****************************************/}
            <Modal
                id={'retailerList'}
                titleId={'retailerList'}
                isOpen={retailersState}
                setIsOpen={setRetailersState}
                isStaticBackdrop={true}
                isScrollable={false}
                isCentered={true}
                isAnimation={false}
            >
                <ModalHeader setIsOpen={setRetailersState} className="bg-l90-warning bg-gradient mb-3">
                    <ModalTitle id='title'>
                        <h5 className="mb-0 text-dark">Common Retailer List</h5>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='releaseNoteWrapper grabNotes'>
                        <ul className='keyFeaturesList d- flex-wrap'>
                            <li className=''>
                                <strong>Walmart</strong> : <a href="https://www.walmart.com/">https://www.walmart.com/</a>
                            </li>
                            <li>
                                <strong>Target</strong> : <a href="https://www.target.com/">https://www.target.com/</a>
                            </li>
                            <li>
                                <strong>BestBuy</strong>  : <a href="https://www.bestbuy.com/">https://www.bestbuy.com/</a>
                            </li>
                            <li>
                                <strong>GameStop</strong>  : <a href="https://www.gamestop.com/">https://www.gamestop.com/</a>
                            </li>
                            <li>
                                <strong>Amazon</strong>  : <a href="https://www.amazon.com">https://www.amazon.com</a>
                            </li>
                        </ul>
                    </div>

                </ModalBody>
            </Modal>
            {/************************************* *
					Start How it works Modal 
			*****************************************/}
            <Modal
                id={'howItWorks'}
                titleId={'howItWorks'}
                isOpen={howItWorksState}
                setIsOpen={setHowItWorksState}
                isStaticBackdrop={false}
                isScrollable={false}
                isCentered={true}
                isAnimation={false}
            // size={'lg'}
            >
                <ModalHeader setIsOpen={setHowItWorksState} className="landingpageWrap align-items-start">
                    <ModalTitle id='title' className='m-0'>
                        <h3 className="m-0 text-dark">How it <span className='font-secondary color-primary'>Works?</span></h3>
                        <p>A very simple steps how to get things done, and get cash.</p>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="landingpageWrap">
                    <div className="rs-feature-box-1 bg-primary">
                        <span className="fa fw-bold">1</span>

                        <div className="body d-flex align-items-center">
                            <div>
                                <img src={cashImg} alt="" width="180" />
                            </div>
                            <div className='w-100 ms-4'>
                                <h4 className="font-quaternary font-weight-extrabold mb-2 line-height-1">Parents <span className='family-cursive'>Chore Cash</span></h4>
                                <p className='mb-0'>Parent put a stockpile of chore cash $$ on your card.</p>
                            </div>
                        </div>
                    </div>
                    <div className="rs-feature-box-1 bg-secondary">
                        <span className="fa fw-bold bg-secondary">2</span>

                        <div className="body d-flex align-items-center">
                            <div className='w-100 me-4'>
                                <h4 className="font-quaternary font-weight-extrabold mb-2 line-height-1">Doing <span className='family-cursive'>Chores</span></h4>
                                <p className='mb-0'>Replenish your stockpile by doing chores.</p>
                            </div>
                            <div>
                                <img src={doingChoreImg} alt="" width="180" />
                            </div>
                        </div>
                    </div>
                    <div className="rs-feature-box-1 bg-tertiary">
                        <span className="fa fw-bold">3</span>

                        <div className="body d-flex align-items-center">
                            <div>
                                <img src={doneChoreImg} alt="" width="180" />
                            </div>
                            <div className='w-100 ms-4'>
                                <h4 className="font-quaternary font-weight-extrabold mb-2 line-height-1">Make a <span className='family-cursive'>chore done</span></h4>
                                <p className='mb-0'>Make a chore done, get your parent to confirm  <br /> and BOOM! stockpile refilled.</p>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>

            {/************************************* *
					Start Reward Option Modal 
			*****************************************/}
            <Modal
                id={'rewardOption'}
                titleId={'rewardOption'}
                isOpen={rewardOptionsState}
                setIsOpen={setRewardOptionsState}
                isStaticBackdrop={false}
                isScrollable={false}
                isCentered={true}
                isAnimation={false}
            // size={'lg'}
            >
                <ModalHeader setIsOpen={setRewardOptionsState} className="landingpageWrap align-items-start pb-5">
                    <ModalTitle id='title' className='m-0'>
                        <h3 className="m-0 text-dark">Reward <span className='font-secondary color-primary'>Options</span></h3>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="landingpageWrap">
                    <div className="rs-feature-box-1 bg-primary pb-2">
                        <span className="fa fw-bold family-cursive fs-2 d-flex justify-content-center" style={{ paddingTop: '35px' }}>Cash</span>

                        <div className="body d-flex align-items-center" style={{ top: '-40px' }}>
                            <div>
                                <img src={cashRewardImg} alt="" width="180" />
                            </div>
                            <div className='w-100 ms-4'>
                                <p className='mb-0 text-end'>Cash rewards are monetary payments given to kids after they complete assigned tasks or chores. In a chores app, these rewards are typically added to a digital wallet or balance within the app.</p>
                            </div>
                        </div>
                    </div>
                    <div className="rs-feature-box-1 bg-secondary">
                        <span className="fa fw-bold family-cursive bg-secondary fs-2 d-flex justify-content-center" style={{ paddingTop: '35px' }}>Savings</span>

                        <div className="body d-flex align-items-center" style={{ top: '-20px' }}>
                            <div className='w-100 me-4'>
                                <p className='mb-0 text-start'>Saving means putting aside a part of your money instead of spending it all at once. It helps you build up your funds for something special in the future or for unexpected needs.</p>
                            </div>
                            <div>
                                <img src={savingRewardImg} alt="" width="180" />
                            </div>
                        </div>
                    </div>
                    <div className="rs-feature-box-1 bg-tertiary pb-2">
                        <span className="fa fw-bold family-cursive fs-2 d-flex justify-content-center" style={{ paddingTop: '35px' }}>Retailers</span>

                        <div className="body d-flex align-items-center" style={{ top: '-40px' }}>
                            <div>
                                <img src={retailersRewardImg} alt="" width="180" />
                            </div>
                            <div className='w-100 ms-4'>
                                <p className='mb-0 text-end'>Spending means using the money you’ve earned to buy things you want or need. It’s like when you go shopping and pay for a toy, a game, or a treat with your own money.</p>
                            </div>
                        </div>
                    </div>
                    <div className="rs-feature-box-1 bg-quaternary pb-2">
                        <span className="fa fw-bold family-cursive bg-quaternary fs-2 d-flex justify-content-center" style={{ paddingTop: '35px' }}>Stocks</span>

                        <div className="body d-flex align-items-center" style={{ top: '-20px' }}>
                            <div className='w-100 me-4'>
                                <p className='mb-0 text-start'>Investing in stocks might sound like something only grown-ups do, but it’s actually a smart way for kids to make their money grow over time. Here’s why you might want to use some of the money you earned from your chores app to invest in stocks.</p>
                            </div>
                            <div>
                                <img src={stocksRewardImg} alt="" height="160" />
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default Landing;